@import url('https://fonts.googleapis.com/css2?family=Otomanopee+One&display=swap');

.mainContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
}

h1 {
    font-family: "Otomanopee One", Roboto,sans-serif;
    font-size: 200%;
    font-weight: normal;
  }

.container {
    margin-top: 80px;
}

.subContainer {
    margin: 10px;
    display: flex;
    flex-direction: row;
}

@media (max-width: 800px) {
    .subContainer {
        flex-direction: column;
    }
}

.robot {
    flex: 2;
    display: flex;
    justify-content: center;
    align-items: center;
}

.infos {
    flex: 3;
    padding-left: 20px;
    padding-bottom: 30px;
}

.title {
    font-size: 3.5rem;
    margin: 0;
}

.subTitle {
    font-size: 2.4rem;
    margin: 0;
}

.text {
    margin: 40px auto;
    font-size: 1.2rem;
}

.defaultBtn {
    background: #571088;
    font-family:-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto",sans-serif;
    font-weight: 500;
    padding: 10px 30px;
    border-radius: 70px;
    font-size: 1.2rem;
    height:auto;
    color: white;
    border: none;
    text-decoration: none;
    text-align: center;
}

.defaultBtn:hover {
    background: #4c0c78;
    text-decoration: none;
    cursor: pointer;
    color: white;
}